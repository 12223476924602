import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cva, VariantProps } from 'class-variance-authority';

import { cn } from '../../utils/cn';
import { OmitStylesComponentProps } from '../../types';

const popoverContentVariants = cva(
    'rocco-popover-content z-popover w-fit will-change-transform',
    {
        variants: {
            variant: {
                default:
                    'before:backdrop-hack data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 rounded-2xl shadow-lg outline-none before:rounded-2xl before:backdrop-blur-2xl',
                glassy: 'before:backdrop-blur-island before:backdrop-hack before:rounded-2xl before:bg-gray-100/50 shadow-lg rounded-2xl outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
            },
            paddings: {
                default: 'px-7 py-6',
                small: 'px-3 py-5',
                xsmall: 'px-2 py-2',
                vertical: 'py-6',
                verticalSmall: 'py-4',
            },
            background: {
                default: 'before:bg-gray-100/85',
                transparent: 'before:bg-transparent',
            },
            size: {
                default: 'w-80',
            },
        },
        defaultVariants: {
            variant: 'default',
            paddings: 'default',
            background: 'default',
        },
    },
);

export const PopoverContent = React.forwardRef<
    React.ElementRef<typeof PopoverPrimitive.Content>,
    OmitStylesComponentProps<typeof PopoverPrimitive.Content> &
        VariantProps<typeof popoverContentVariants> & {
            usePortal?: boolean;
            className?: string;
        }
>(
    (
        {
            align = 'center',
            sideOffset = 4,
            variant,
            paddings,
            background,
            size,
            usePortal = true,
            className,
            ...props
        },
        ref,
    ) => {
        const content = (
            <PopoverPrimitive.Content
                ref={ref}
                align={align}
                sideOffset={sideOffset}
                className={cn(
                    popoverContentVariants({
                        variant,
                        paddings,
                        background,
                        size,
                    }),
                    className,
                )}
                {...props}
            />
        );

        return usePortal ? (
            <PopoverPrimitive.Portal>{content}</PopoverPrimitive.Portal>
        ) : (
            content
        );
    },
);

PopoverContent.displayName = PopoverPrimitive.Content.displayName;
